<template>
    <div>
        <!-- justify contentsを中央揃えに -->
        <v-row justify="center">
            <v-col cols="12" sm="12">
                <v-sheet min-height="70vh" rounded="lg" class="v-container" id="scrolling-techniques-3">
                    <!-- ここに書く -->
                    <div v-for="(row, rowIndex) in this.filteredData" :key="rowIndex">
                        <p id="todayIdou" v-if="row.length >= 3">{{ constMonth }}月{{ Math.floor(row[0]) }}日の移動販売車</p><br>
                        <div id="idou">
                            <div id="yakusa_idou">
                                <p class="subtitle_idou">八草キャンパス</p>
                                <div class="places">
                                    <p class="place">1号館前</p>
                                    <p class="carname">{{ row[2] }}</p>
                                    <p class="place">セントラルガーデン</p>
                                    <p class="carname">{{ row[3] }}</p>
                                </div>
                            </div><br>
                            <div id="ait_idou">
                                <p class="subtitle_idou">自由が丘キャンパス</p>
                                <div class="places">
                                    <p class="place"> 本館２階駐輪場前 </p>
                                    <p v-if="row[4] == null">今日はありません</p>
                                    <p v-else class="carname">{{ row[4] }}</p><br>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-sheet>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'KitchenCar',
    data: () => ({
        csvData: [], // CSVデータを保持する配列
        constDay: 0,
        constMonth: 0,
        constYear: 0,
        place2: []
    }),
    mounted() {
        // 今日の日付を取得
        const today = new Date();
        const year = today.getFullYear();
        const day = today.getDate();
        const month = today.getMonth() + 1;

        // console.log(year);

        this.constMonth = month;
        this.constDay = day;
        this.constYear = year;

        // ページがマウントされた時にCSVを読み込む
        this.loadCSV();
        // console.log('today: ',this.constDay); 

        // for (let x of this.filteredData()){
        //     // this.$emit('car-ok',x[2],x[3]) 
        //     console.log(x[2],x[3]);
        // }

    },
    created: function () {
        this.sendCarName();
        // this.$emit('car-ok', this.place2[0], this.place2[1]);
    },
    computed: {
        filteredData() {
            // 特定の日付と一致する行だけを抽出する
            return this.csvData.filter((row) => parseInt(row[0]) === this.constDay);
        },
    },
    methods: {
        async loadCSV() {
            try {
                // CSVファイルの取得
                // const response = await fetch('latest.csv');
                const response = await fetch(`https://gh.hyouhyan.com/ait-info/idou/${this.constYear}${String(this.constMonth).padStart(2, '0')}.csv`);
                const csvText = await response.text();

                // CSVデータを行ごとに分割
                const rows = csvText.split('\n');

                // CSVデータを二次元配列に変換
                this.csvData = rows.map((row) => row.split(','));

            } catch (error) {
                console.error('Error loading CSV:', error);
            }
        },
        sendCarName: function () {
            let todayData = null;
            todayData = this.filteredData;
            if (todayData.length == 0) {
                setTimeout(() => {
                    this.sendCarName();
                }, 100);
            }
            for (let x of todayData) {
                // console.log(x[2], x[3]);
                this.$emit('car-ok', x[2], x[3]);
            }
            
        },
    },
} 
</script>

<style>
/* テーブルのスタイルを追加 */
table {
    border-collapse: collapse;
    /* セルの線を重ねる */
}

tr:nth-child(odd) {
    background-color: #ddd;
    /* 背景色指定 */
}

th,
td {
    padding: 2px 3px;
    /* 余白指定 */
    border: 0px 1px;
    border-color: #000;
}

p {
    white-space: nowrap;
    display: inline-block;
    /* テキストが折り返されないようにする */
}

.place {
    margin-top: 10px;
    width: min(179vw, 180px);
    display: flex;
    justify-content: center;
}

.places {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 6% 4%;
    background: #FFFFFF;
}

.subtitle_idou {
    padding: 8px 25px;
    background: #9E9E9E;
    width: min(150vw, 200px);
    display: flex;
    justify-content: center;
}

#todayIdou {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-family: "Open Sans", sans-serif;

}

#yakusa_idou {
    /* margin: 20px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2% 3%;
    background: #EEEEEE;
}

#ait_idou {
    /* margin: 20px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2% 3%;
    background: #EEEEEE;
}

#idou {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
</style>