<template>
  <div v-if="this.Cookies == null">
    <LoginPage :aikodai-id="LoginData.aikodaiID" :password="LoginData.password" :debug="this.debug"
      @login-ok="LoadLoginData"></LoginPage>
  </div>
  <div v-else>
    <v-app id="inspire">
      <v-app-bar color="white" flat :class="getPadding()">
        <v-img contain src="../img/logo.png" :width="getIconSize()" :max-width="getIconSize()"
          :height="getIconSize()"></v-img>
        <v-spacer></v-spacer>
        <v-tabs centered color="grey-darken-2" v-model="tab" align-tabs="center" center-active stacked>
          <v-tab v-for="link in links" :key="link.id" :value="link.id" color="pink-lighten-1" :class="getPadding2()"
            :max-width="getTabsWidth()">
            <v-icon>{{ link.icon }}</v-icon>
            <div :class="isDisplayxs() ? 'text-caption' : ''">{{ link.title }}</div>

          </v-tab>
        </v-tabs>
        <v-spacer></v-spacer>
        <template v-slot:append >
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn rounded=""  variant="" :size="getButtonSize()" :class="isDisplayxs() ? 'pa-0 ma-0' : 'ma-0 pa-2 px-0'"  v-bind="props">
                <v-icon color="pink-darken-2" :size="isDisplayxs() ? 'large' : 'large'">mdi-account</v-icon>
              </v-btn>
            </template>
            <v-list >
              <v-list-item key="1" value="1">
                <v-list-item-title>
                  <div :class="isDisplayxs() ? 'text-caption' : ''">{{ LoginData.aikodaiID }}</div>
                </v-list-item-title>
              </v-list-item>
              <v-list-item key="2" value="2" @click="reLogin">
                <v-list-item-title>
                  <div :class="getTextSize()">再ログイン</div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-app-bar>


      <v-main class="bg-grey-lighten-3">
        <v-window v-model="tab">
          <v-window-item v-for="link in  links " :key="link.id" :value="link.id">
            <!-- Mapsのウィンドウ -->
            <v-container v-if="link.id == 1">
              <SchoolMaps :time-table="LoginData.timeTable"></SchoolMaps>
            </v-container>
            <!-- TimeTableのウィンドウ -->
            <v-container v-if="link.id == 2">
              <TimeTable :aikodai-id="LoginData.aikodaiID" :password="LoginData.password"
                :time-table="LoginData.timeTable" @to-login-page="InitLogin"></TimeTable>
            </v-container>
            <!-- WelcomePageのウィンドウ -->
            <v-container v-if="link.id == 3">
              <WelcomePage :now-ait="Now_ait" :now-yakusa="Now_yakusa" :aikodai-id="LoginData.aikodaiID"
                :time-table="LoginData.timeTable"></WelcomePage>
            </v-container>
            <!-- Bus Scheduleのウィンドウ-- > -->
            <v-container v-if="link.id == 4">
              <BusSchedule></BusSchedule>
            </v-container>
            <!-- Kitchen Carのウィンドウ -->
            <v-container v-if="link.id == 5">
              <KitchenCar></KitchenCar>
            </v-container>
            <!-- Informationのウィンドウ -->
            <v-container v-if="link.id == 6">
              <InforMation></InforMation>
            </v-container>
          </v-window-item>
        </v-window>
      </v-main>
      <v-footer class="bg-grey-lighten-1" dark app>
        <v-row justify="center" no-gutters>
          <div>
            {{ this.Now_yakusa }}
            {{ this.Now_ait }}
          </div>
          <!-- <div v-if="!isDisplayxs()">
            <v-btn color="white" variant="text" class="mx-2" rounded="xl" v-for=" link  in   links  " :key="link.id">
              <div :class="getTextSize()">{{ link.title }}</div>
              
            </v-btn>
          </div> -->
          <v-col class=" text-center mt-4" cols="12" :class="getTextSize()">
            {{ new Date().getFullYear() }} — <strong>AITPlus</strong>
          </v-col>
        </v-row>
      </v-footer>
    </v-app>
  </div>
</template>


<script>
import SchoolMaps from './SchoolMaps.vue'
import TimeTable from './TimeTable.vue'
import WelcomePage from './WelcomePage.vue'
import BusSchedule from './BusSchedule.vue'
import KitchenCar from './KitchenCar.vue'
import InforMation from './InforMation.vue'
import LoginPage from './LoginPage.vue'
import { getCurrentInstance } from 'vue';
// import jsonTimetable from "@/assets/json/timetable.json"


const usevuetify = () => {
  const instance = getCurrentInstance()
  if (!instance) {
    throw new Error(`vuetify instance doesn't exist`)
  }
  return instance.proxy.$vuetify
}
export default {
  name: 'App',
  data: () => ({
    debug: false,
    Cookies: null,
    tab: null,
    links: [
      { id: 1, title: 'Maps', icon: 'mdi-map-marker' },
      { id: 2, title: 'TimeTable', icon: 'mdi-timetable' },
      { id: 3, title: 'Home', icon: 'mdi-home' },
      { id: 4, title: 'Bus Schedule', icon: 'mdi-bus-clock' },
      { id: 5, title: 'Kitchen Car', icon: 'mdi-food' },
      { id: 6, title: 'Information', icon: 'mdi-information' },
    ],
    LoginData: {
      aikodaiID: null,
      password: null,
      timeTable: null,
    },
    showFlg: false,
  }),
  components: {
    /* eslint-disable */
    SchoolMaps,
    TimeTable,
    WelcomePage,
    KitchenCar,
    InforMation,
    BusSchedule,
    LoginPage,
    /* eslint-disable */
  },
  // ここにメソッドを書く
  methods: {
    getCookies: function () {
      if (document.cookie === '') {
        return null
      }
      const cookies = document.cookie.split(';')
      const cookiesMap = {}
      cookies.forEach(function (cookie) {
        const content = cookie.split('=')
        if (content[0].trim() === 'timeTable') {
          cookiesMap[content[0].trim()] = JSON.parse(decodeURIComponent(content[1]))
        } else {
          cookiesMap[content[0].trim()] = content[1]
        }
      })
      return cookiesMap
    },
    setCookie: function (key, value) {
      let cookie = `${key}=${value};`
      let expire = '';
      //Cookieを保存する期間(days)を指定
      let period = 7;
      // Cookieを保存する期間(ms)を設定
      let during = 1000 * 3600 * 24 * period;

      expire = new Date();
      expire.setTime(expire.getTime() + during);
      // expire.toUTCString();
      cookie += 'expires=' + expire.toUTCString() + ';';

      document.cookie = cookie
    },
    deleteCookie: function (key) {
      document.cookie = `${key}=; max-age=0`
    },

    LoadLoginData(id, ps, tt) {
      this.LoginData.aikodaiID = id
      this.LoginData.password = ps
      this.LoginData.timeTable = tt
      this.setCookie('aikodaiID', id)
      // this.setCookie('password', ps)
      this.setCookie('timeTable', encodeURIComponent(JSON.stringify(tt)))
      this.Cookies = this.getCookies()
      // console.log("New cookie = ")
      // console.log(this.Cookies)

    },
    InitLogin() {
      this.LoginData.aikodaiID = null
      this.LoginData.password = null
      this.LoginData.timeTable = null
      this.Cookies = null
    },
    reLogin: function () {
      this.InitLogin()
      this.deleteCookie('aikodaiID')
      this.deleteCookie('timeTable')
    },

    isDisplayxs: function () {
      const vuetify = usevuetify();
      return vuetify.display.xs;
    },
    getButtonSize: function () {
      if (this.isDisplayxs()) {
        return 'small'
      } else {
        return 'large'
      }
    },
    getPadding: function () {
      if (this.isDisplayxs()) {
        return 'px-0'
      } else {
        return 'px-0'
      }
    },
    getPadding2: function () {
      if (this.isDisplayxs()) {
        return 'pa-0.5'
      } else {
        return 'pa-6'
      }
    },
    getPaddingBtn: function () {
      if (this.isDisplayxs()) {
        return 'pa-0 ma-0'
      } else {
        return ''
      }
    },
    getIconSize: function () {
      if (this.isDisplayxs()) {
        return '25'
      } else {
        return '50'
      }
    },
    getTabsWidth: function () {
      if (this.isDisplayxs()) {
        return '25'
      } else {
        return 'auto'
      }
    },
    getTextSize: function () {
      if (this.isDisplayxs()) {
        return 'text-caption'
      } else {
        return 'text-body-2'
      }
    },
    showId: function () {
      this.showFlg = !this.showFlg
    },
  },
  computed: {

  },
  created: function () {
    for (let x in this.links) {
      this.tab = this.links[x].id
    }
    this.tab = 3
    // デバッグモード
    if (this.debug) {
      // this.LoginData.aikodaiID = 'a12345bc'
      // this.LoginData.password = 'test'
      // this.LoginData.timeTable = jsonTimetable
    }
    new Promise((resolve) => {
      // console.log("Before cookie = ")
      this.Cookies = this.getCookies()
      // console.log(this.Cookies)
      if (this.Cookies == null) {
        resolve()
        return
      }
      this.LoginData.aikodaiID = this.Cookies.aikodaiID
      this.LoginData.password = 'cookie'
      this.LoginData.timeTable = this.Cookies.timeTable
      resolve()
    })
    // cookieの削除
    .then(() => {
      // this.deleteCookie('aikodaiID')
      this.deleteCookie('password')
      // this.deleteCookie('timeTable')

      // console.log("After cookie = " + document.cookie)
    })

  },
}
</script>

<style>
.sheet {
  padding: 100px;
}
</style>