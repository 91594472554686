<template>
    <div>
        <!-- justify contentsを中央揃えに -->
        <v-row justify="center">
            <v-col cols="12" sm="12">
                <div class="v-sheet v-theme--light rounded-lg v-container" id="scrolling-techniques-3" style="min-height: 70vh;">
                    <!-- ここに書く -->
                    <div id="all">
                        <div id="titles">
                            <!-- 時間<p>{{ dayOfWeek }} {{ now }}</p> -->
                            <span>{{title}}{{errorMessage}}</span><br>
                            <span>{{nextLecture}}{{outNextRoom}}</span>
                        </div>
                        <div ref="map" id="mapArea" style="height: 500px;"></div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>




<script>
// import jsonTimetable from "@/assets/json/timetable.json"
// import jsonTimetable from "@/assets/json/sample.json"

export default {
    name: 'SchoolMaps',

    data: () => ({
        latitude: 0, // 緯度
        longitude: 0, // 経度
        title: '次の講義', // title
        nextLecture: 'アラビア', // 次の講義名
        nextRoom: '0000', // 次の講義室
        outNextRoom: '000', // 次の講義室(号館)
        TimeTables: [], // json(時間割)
        now: '00:00:00', // 現在時刻
        week: 0, // 今日の曜日(数値)
        dayOfWeek: '日', // 今日の曜日(文字)
    }),
    props: {
        timeTable: {

        },
    },

    created() { // 最初に1回実行
        this.TimeTables = this.timeTable;
        this.getDate();
        setInterval(this.getDate, 1000);
    },

    watch: { // 変数が変わったら実行
        longitude() {
            this.showMap();
        },
        now() {
            if(this.week <= 4) { // 土日以外
                const time = Number(this.date.getHours()) * 60 + Number(this.date.getMinutes())
                if(time > 1170) { // 放課後
                    this.title = '本日の講義は終了しました';
                    this.default();
                } else {
                    this.title = '次の講義'
                    this.getNext();
                }
            } else {
                this.default();
            }
                
        },
        nextRoom() {
            const BuildingNumber = this.nextRoom.substr(0,2);
            this.BuildingNumber = 10;
            this.errorMessage = '';
            let defa = false;
            // console.log(BuildingNumber);
            if (BuildingNumber == '') { // 愛工大(土日など)
                this.title = '本日の講義はありません';
                this.latitude = 35.18344358923007,
                this.longitude = 137.11303896196864
                defa = true;
            } else if (BuildingNumber == '1-') { // 1号館
                this.latitude = 35.18393945476342, 
                this.longitude = 137.11122595608316
            } else if (BuildingNumber == '1号') { // 1号館別館
                this.latitude = 35.184755757058426,
                this.longitude = 137.1109978157656
            } else if (BuildingNumber == '新2') { // 新2号館
                this.latitude = 35.183703880504815, 
                this.longitude = 137.11419974761313
            } else if (BuildingNumber == '2号') { // 2号館実験棟
                this.latitude = 35.18412202460053, 
                this.longitude = 137.11295508019342
            } else if (BuildingNumber == 'C1') { // 3号館
                this.latitude = 35.183922211024395,
                this.longitude = 137.11504557393604
            } else if (BuildingNumber == 'C2') { // 3号館別館
                this.latitude = 35.18370320371449,
                this.longitude = 137.11509877225797
            } else if (BuildingNumber == 'K4') { // 4号館
                this.latitude = 35.18482994471485, 
                this.longitude = 137.1150659575287
            } else if (BuildingNumber == 'ER') { // 5号館(2,3階)
                this.latitude = 35.18458493374964,
                this.longitude = 137.1140735165559
            } else if (BuildingNumber == '機器') { // 5号館(1階)機器実験室
                this.latitude = 35.18458493374964,
                this.longitude = 137.1140735165559
            } else if (BuildingNumber == '計測') { // 5号館(1階)計測実験室
                this.latitude = 35.18458493374964,
                this.longitude = 137.1140735165559
            } else if (BuildingNumber == '6号館') { // 6号館(存在しない)
                this.latitude = 35.18473046120468,
                this.longitude = 137.11610454506928 
            } else if (BuildingNumber == '7-') { // 7号館
                this.latitude = 35.1846817556055,
                this.longitude = 137.11249795736597
            } else if (BuildingNumber == '8-') { // 8号館
                this.latitude = 35.18420802854306,
                this.longitude = 137.11458722046635
            } else if (BuildingNumber == 'G3') { // 9号館
                this.latitude = 35.18475873791302, 
                this.longitude = 137.11156065100775
            } else if (BuildingNumber == 'G2') { // 10号館
                this.latitude = 35.18419280914104, 
                this.longitude = 137.110973746277
            } else if (BuildingNumber == '10') { // 10号館
                this.latitude = 35.18419280914104, 
                this.longitude = 137.110973746277
            } else if (BuildingNumber == '11') { // 11号館
                this.latitude = 35.185232783157495, 
                this.longitude = 137.11593943875485
            } else if (BuildingNumber == '12') { // 12号館
                this.latitude = 35.18372755469411,
                this.longitude = 137.1134808811515
            } else if (BuildingNumber == 'みら') { // 12号館(みらい工房)
                this.latitude = 35.18372755469411,
                this.longitude = 137.1134808811515
            } else if (BuildingNumber == 'エク') { //  12号館(エクステンションセンター)
                this.latitude = 35.18372755469411,
                this.longitude = 137.1134808811515
            } else if (BuildingNumber == '13') { // 13号館
                this.latitude = 35.184737832991345, 
                this.longitude = 137.1157716465662
            } else if (BuildingNumber == '鉀徳') { // 鉀徳館
                this.latitude = 35.18217703206552, 
                this.longitude = 137.11378490226392
            } else if (BuildingNumber == '情報') { // 情報教育センター
                this.latitude = 35.184584453383856, 
                this.longitude = 137.11499536289404
            } else if (BuildingNumber == 'セー') { // 計算センター
                this.latitude = 35.18460936006928, 
                this.longitude = 137.11453802694152
            } else if (BuildingNumber == 'マイ') { // 計算センター
                this.latitude = 35.18460936006928, 
                this.longitude = 137.11453802694152 
            } else if (BuildingNumber == 'バイ') { // バイオ環境化学実験棟
                this.latitude = 35.18369498063773,
                this.longitude = 137.11545882058513
            } else if (BuildingNumber == '耐震') { // 耐震実験センター
                this.latitude = 35.185177934719206,
                this.longitude = 137.1111278810919
            } else if (BuildingNumber == '小体') { // 小体育館
                this.latitude = 35.18146043851598,
                this.longitude = 137.1133354009275
            } else { // 講義室がなかった時
                this.latitude = 35.18344358923007,
                this.longitude = 137.11303896196864
                this.errorMessage = '(対応していない講義室です)'
                defa = true;
            }

            if(defa) {
                this.outNextRoom = this.nextRoom;
            } else {
                this.outNextRoom = '(' + this.nextRoom + ')';
            }
        },
    },

    methods: {

        getDate() { // 現在時刻 & 今日の曜日取得
            const weekItems = ["月", "火", "水", "木", "金", "土","日"];
            this.date = new Date();
            this.week = this.date.getDay() - 1;
            if(this.week == -1) {
                this.week = 6;
            }
            this.dayOfWeek = weekItems[this.week];  
            this.now = this.date.getHours() + ":" + this.date.getMinutes() + ":" + this.date.getSeconds();
        },

        default() { // 土日などの時
            this.nextLecture = '';
            this.nextRoom = '';
        },

        getNext() { // 現在時刻から次の講義を検索
            const minutesOfLecture = [540,640,780,880,980,1080]; // 講義の時間(分で表現)
            const toDayLectures = this.TimeTables[this.week].classes; // 今日の講義
            if(Object.keys(toDayLectures).length == 0) { // 授業がない時
                this.default();
            } else {
                let minMinuDis = 100000000; // min minute distance
                let min = 0; // 最小distanceのindex
                for(let i = 0; i < Object.keys(toDayLectures).length; i++) { // 一番近いのを探す
                    const period = toDayLectures[i].period; // n限
                    const minuDis = Math.abs(minutesOfLecture[period - 1] - (Number(this.date.getHours()) * 60 + Number(this.date.getMinutes())));

                    if(minMinuDis > minuDis) {
                        minMinuDis = minuDis;
                        min = i;
                    }
                }
                // console.log(toDayLectures[min].name);
                if(this.nextLecture != toDayLectures[min].name) { // 変化があったら
                    this.nextLecture = toDayLectures[min].name;
                    this.nextRoom =  toDayLectures[min].locate;
                }
            }
        }, 

        showMap() { // 地図を表示
            // APIキーを使用しスクリプト呼び出すやつ
            const script = document.createElement('script');
            script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyD31Xh6RcYFzbDYNl05jZ9gp7ZZ9BZWni0&callback=initMap' 
            + "&libraries=places";
            script.async = true;
            document.head.appendChild(script);

            // スクリプト呼び出し後のコールバック
            window.initMap = () => {
                const myLatLng = { lat: Number(this.latitude), lng: Number(this.longitude) };
                const map = new window.google.maps.Map(this.$refs.map, {
                center: myLatLng,
                zoom: 17,
                });
                new window.google.maps.Marker({ 
                    position: myLatLng, 
                    map 
                });
            };
        },
    }
}
</script>

<style>
#titles {
    text-align: center;
    margin-bottom: 12px;
    font-size: 1.3em;
    span {
        overflow-wrap: break-word;
        width:100%; /* 追加 */
        max-width:100%; /* 追加 */
    }
}
</style>
