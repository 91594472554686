<template>
    <div>
        <!-- justify contentsを中央揃えに -->
        <v-row justify="center">
            <v-col cols="12" sm="12">
                <v-sheet min-height="70vh" rounded="lg" class="v-container" id="scrolling-techniques-3">
                    <!-- ここに書く -->
                    <div id="containerBus">
                        <p id="daiya">今日は {{ today }} ダイヤ</p><br>
                        <v-row>
                            <div id="now" style="max-width: 45vw;">
                                <p id="subtitle">八草発</p><br>
                                <p><span v-for="(time, index) in now_yakusa" :key="index">
                                        <div id="list_yakusa">
                                            {{ time }}<br>
                                        </div>
                                    </span></p>
                            </div><br>
                        <!-- <v-col> -->
                            
                            <div id="now" style="max-width: 40vw;">
                                <p id="subtitle">愛知工業大学発</p><br>
                                <p>
                                    <span v-for="(time, index) in now_ait" :key="index">
                                        <div id="list_ait">
                                            {{ time }}<br>
                                        </div>
                                    </span>
                                </p>
                            </div><br>
                            <!-- </v-col> -->

                        </v-row>
                    </div>




                    <p id="subtitle">八草発</p>
                    <table v-for="(TodayBusSchedule_yakusa, time) in TodayBusSchedule_yakusa" :key="time">
                        <td v-if="time >= 8">
                            {{ time }}:{{ TodayBusSchedule_yakusa.map(schedule => ('0' + schedule).slice(-2)).join(" "+time+":") }}
                        </td>
                    </table><br>
                    <div id="aitStart">
                        <p id="subtitle">愛知工業大学発</p>
                        <table v-for="(TodayBusSchedule_ait, time) in TodayBusSchedule_ait" :key="time">
                            <td v-if="time >= 8">
                                {{ time }}:{{ TodayBusSchedule_ait.map(schedule => ('0' + schedule).slice(-2)).join(" "+time+":") }}
                            </td>
                        </table>
                    </div>
                </v-sheet>
            </v-col>
        </v-row>
    </div>
</template>

<script>
// JSON ファイルの読み込み
import jsonDataABC from "@/assets/data/bus_ABC-R5.json";
import jsonDataDiagram from "@/assets/data/bus_diagram-R5.json";
export default {
    name: 'BusSchedule',
    data: () => ({
        TodayBusSchedule_yakusa: [],  // バスのスケジュールを格納するデータyakusa発
        TodayBusSchedule_ait: [],  // バスのスケジュールを格納するデータait発
        today: "",
        now_yakusa: [],
        now_ait: []
    }),

    mounted() {
        // 今日の日付を取得
        const today = new Date();
        const month = today.getMonth() + 1;  // 月は0から始まるので+1する
        const day = today.getDate();
        const yakusa = "yakusa";
        const ait = "ait";
        let count = 0;

        // ダイヤグラムを取得
        const todayDiagram = jsonDataABC[month][day];
        this.today = todayDiagram;
        // console.log('Today\'s Diagram:', todayDiagram);
        // console.log('todayDiagram A/B/C?:', todayDiagram);
        // console.log("today:", today);

        //今日のバスのダイヤの一覧表示
        this.getTodayBusSchedule(todayDiagram, yakusa, jsonDataDiagram, count);
        this.getTodayBusSchedule(todayDiagram, ait, jsonDataDiagram, count);

        // console.log('now ScheduleY:', this.now_yakusa);
        // console.log('now ScheduleA:', this.now_ait);
        this.$emit('bus-ok', this.now_yakusa[0], this.now_ait[0]);
    },

    methods: {
        getTodayBusSchedule(todayDiagram, place, jsonDataDiagram, count) {
            const now = new Date();
            const currentHour = now.getHours();
            const currentMinutes = now.getMinutes();

            //8-20時まで全部
            for (let time = 8; time <= 21; time++) {
                if (todayDiagram != null) {
                    //全部配列に格納
                    const todaySchedule = jsonDataDiagram[todayDiagram][place][time];

                    // 最新3つを探して保存
                    if (count < 3) {
                        for (const minute of todaySchedule) {
                            var AllMinute = (time * 60) + minute;
                            var NowMinute = (currentHour * 60) + currentMinutes;
                            const scheduledTime = `${time}:${('0' + minute).slice(-2)}`;

                            if (AllMinute >= NowMinute && count < 3) {
                                if (place === "yakusa") {
                                    this.now_yakusa[count] = scheduledTime;
                                    // console.log('yakusa:', scheduledTime);
                                } else {
                                    this.now_ait[count] = scheduledTime;
                                    // console.log('ait:', scheduledTime);
                                }
                                count++;

                            }
                        }
                    }
                    // console.log('Today Bus Schedule:', time, ':', todaySchedule);

                    // TodayBusSchedule にデータを追加
                    if (place == "yakusa") {
                        this.TodayBusSchedule_yakusa[time] = todaySchedule;
                    } else {
                        this.TodayBusSchedule_ait[time] = todaySchedule;
                    }
                }
                // elseの時は何もしない
            }

            count = 0;
            // console.log('now ScheduleY:', this.now_yakusa);
            // console.log('now ScheduleA:', this.now_ait);


        },
    },
    created: function () {



    },
    getLatestBuses(busSchedule) {
        const now = new Date();
        const currentHour = now.getHours();
        const currentMinutes = now.getMinutes();
        const currentTime = currentHour * 100 + currentMinutes;

        // 最新のバスのダイヤを取得
        const latestBuses = busSchedule.filter(time => time > currentTime);
        // 最初の3つだけ取得
        return latestBuses.slice(0, 3);
    },

} 
</script>

<style>
body {
    font-family: "Open Sans", sans-serif;
    line-height: 1.25;
}

#containerBus {
    font-family: "Open Sans", sans-serif;
    display: grid;
    justify-content: center;
    justify-items: center;
}

#aitStart {
    font-family: "Open Sans", sans-serif;
}

#daiya {
    text-align: center;
    display: flex;
    font-size: 25px;
    justify-content: center;
}

#now {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#subtitle {
    font-family: "Open Sans", sans-serif;
    margin: 10px 0px;
    font-weight: bold;
    flex-direction: column;
    gap: 20px;
}

#list_yakusa {
    font-family: 'Arial';
    font-size: 30px;
    justify-content: center;
    margin: 20px;
    display: grid;
    align-items: center;
    padding: 10px;
    width: 150px;
    height: 150px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

#list_ait {
    font-family: 'Arial';
    font-size: 30px;
    justify-content: center;
    margin: 20px;
    display: grid;
    align-items: center;
    padding: 10px;
    width: 150px;
    height: 150px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

table td {
    border-bottom: 1px solid #b5b1b1;
    color: black;
    margin-bottom: 20px;
    /* background-color: azure; */
}
</style>