import { createApp } from 'vue'
import App from './components/DemoPage.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import './registerServiceWorker'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBmwUmgqOAOTuX8z2t1GT0NkWKRcSnLVIo",
    authDomain: "ait-plus.firebaseapp.com",
    projectId: "ait-plus",
    storageBucket: "ait-plus.appspot.com",
    messagingSenderId: "154209881918",
    appId: "1:154209881918:web:1f850f4f430c1f7d3e666f",
    measurementId: "G-H9L2SR9R16"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

// messaging.usePublicVapidKey("BHdAsB4GrfIU1LjQwxKBOXtEHlMI2uj_vlAhXwjzFp5g611cjN219zbxRjk9dpp-bRgJvfsWVOcClghQr9QzvtM");
getToken(messaging, { vapidKey: "BHdAsB4GrfIU1LjQwxKBOXtEHlMI2uj_vlAhXwjzFp5g611cjN219zbxRjk9dpp-bRgJvfsWVOcClghQr9QzvtM" }).then((currentToken) => {
    if (currentToken) {
        // Send the token to your server and update the UI if necessary
        // ...
        // console.log("your token is " + currentToken)
    } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
        // ...
    }
}).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // ...
});

loadFonts()



createApp(App)
  .use(vuetify)
  .mount('#app')

console.log('Hello from AIT Plus!')