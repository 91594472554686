<template>
    <div>
        <!-- justify contentsを中央揃えに -->
        <v-row justify="center">
            <v-col cols="12" sm="12">
                <v-sheet min-height="70vh" rounded="lg" id="scrolling-techniques-3">
                    <!-- ここに書く -->
                    <!-- ゲストのとき -->
                    <v-container v-if="AikodaiId == 'guest'">
                        <v-card class="mx-auto" max-width="500">
                            <v-card-title class="text-h6 font-weight-regular justify-space-between">
                                <span>時間割にアクセス出来ません！！</span>
                            </v-card-title>
                            <v-card-text>
                                <div class="pa-4 text-center">
                                    <h3 class="text-h6 font-weight-light mb-2">
                                        ゲストユーザーは時間割にアクセス出来ません。
                                        アクセスするにはログインしてください。
                                    </h3>
                                    <span class="text-caption text-grey">ログインは下のボタンから行うことができます</span>
                                </div>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue-darken-1" variant="flat" @click="toLoginPage">
                                    今すぐログインを行う
                                </v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-container>
                    <!-- ログイン出来ているとき -->
                    <v-container v-else>
                        <div v-if="!isDispSize()">
                            <v-table density="compact">
                                <thead>
                                    <tr>
                                        <th class="text-center" v-for="Day in DayOfWeek" :key="Day.id" :id="'youbi-'+Day.id">
                                            {{ Day.title }}
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>

                                    <tr class="text-center" v-for="period in maxPeriod" :key="period">

                                        <td v-for="Day in DayOfWeek" :key="Day.id" color="blue-grey-lighten-2">
                                            <div v-if="Day.id == 0">
                                                {{ period }}限
                                            </div>
                                            <div v-else>
                                                <div v-for="TimeTable in TimeTables" :key="TimeTable.youbi">
                                                    <div v-for="Class in TimeTable.classes" :key="Class.period">
                                                        <!-- :height="getCardHeight" -->
                                                        <v-card class="panda pa-0 mt-2" :width="getCardWidth" min-height="150" v-if="Class.period == period && TimeTable.youbi == Day.title">

                                                            <div class="text-center"
                                                                v-if="Class.period == period && TimeTable.youbi == Day.title">
                                                                <v-card-text>
                                                                    <h6 class="text-body-2 font-weight-light ">
                                                                        {{ Class.name }}
                                                                    </h6>
                                                                    <span class="text-caption text-grey">
                                                                        {{ Class.teacher }}
                                                                        <br>
                                                                        {{ Class.locate }}
                                                                    </span>
                                                                </v-card-text>
                                                            </div>
                                                            <div v-else>


                                                            </div>


                                                        </v-card>
                                                        <div v-else >
                                                           <v-card :width="getCardWidth"></v-card>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </td>

                                        <!-- <td v-for="Class in TimeTable.classes" :key="Class.Period">

                                                {{ Class.name }}
                                                {{ Class.teacher }}
                                                {{ Class.locate }}
                                            </td> -->
                                    </tr>

                                </tbody>
                            </v-table>
                        </div>
                        <div v-else>
                            <v-select  :items="weeks" label="曜日を選択してください" solo  @update:modelValue="(event)=>{changeDayOfWeek2(weeks.indexOf(event)+1)}"></v-select>
                            <v-table density="compact" v-for="Day in DayOfWeek2" :key="Day.id" >
                                <thead>
                                    <tr class="" color="">
                                        <th class="text-center space" >

                                        </th>
                                        <th class="text-center" :id="'youbi-'+Day.id">
                                            {{ Day.title }}
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>

                                    <tr class="text-center" v-for="period in maxPeriod" :key="period">
                                        <td class="text-center">
                                            {{ period }}限
                                        </td>

                                        <td id="kamoku">
                                            <div v-if="Day.id == 0">

                                            </div>
                                            <div v-else>
                                                <div v-for="TimeTable in TimeTables" :key="TimeTable.youbi">
                                                    <div v-for="Class in TimeTable.classes" :key="Class.period"
                                                        class="text-center">
                                                        <!-- :height="getCardHeight" -->
                                                        <div class="text-center"
                                                            v-if="Class.period == period && TimeTable.youbi == Day.title">
                                                            <v-card class="panda pa-0 mt-2" :width="getCardWidth" justify="center">

                                                                <div class="text-center"
                                                                    v-if="Class.period == period && TimeTable.youbi == Day.title">
                                                                    <v-card-text>
                                                                        <h6 class="text-body-2 font-weight-light ">
                                                                            {{ Class.name }}
                                                                        </h6>
                                                                        <span class="text-caption text-grey">
                                                                            {{ Class.teacher }}
                                                                            <br>
                                                                            {{ Class.locate }}
                                                                        </span>
                                                                    </v-card-text>
                                                                </div>
                                                                <div v-else>

                                                                </div>


                                                            </v-card>
                                                        </div>
                                                        <div v-else>
                                                            <!-- <v-card :width="getCardWidth"></v-card> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </td>

                                        <!-- <td v-for="Class in TimeTable.classes" :key="Class.Period">

                                                {{ Class.name }}
                                                {{ Class.teacher }}
                                                {{ Class.locate }}
                                            </td> -->
                                    </tr>

                                </tbody>
                            </v-table>
                        </div>
                    </v-container>
                </v-sheet>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { getCurrentInstance } from 'vue';

const usevuetify = () => {
    const instance = getCurrentInstance()
    if (!instance) {
        throw new Error(`vuetify instance doesn't exist`)
    }
    return instance.proxy.$vuetify
}

//時間割のjsonファイルを読み込む
//あとでAPIから取得するようにするため、URLを記述する
// const timeTableUrl = `https://api.aitplus.hyouhyan.com/timetable/index.php?id=${aitId}&pass=${aitPass}`

// let jsonTimetable;

// fetch(timeTableUrl)
//     .then(result => result.json())
//     .then((output) => {
//         console.log('Output: ', output);
//        jsonTimetable = JSON.parse(output);
//        jsonTimetable = output;
//     })
//     .catch(err => console.error(err));

// import LoginPage from "./LoginPage.vue"
// let jsonTimetable = LoginPage.timeTabla

export default {

    name: 'TimeTable',
    data: () => ({
        AikodaiId: 0,
        PassWord: 0,
        SelctWeek:[
            '月',
            '火',
            '水',
            '木',
            '金',
        ],
        DayOfWeek: [
            { id: 0, title: '' },
            { id: 1, title: '月' },
            { id: 2, title: '火' },
            { id: 3, title: '水' },
            { id: 4, title: '木' },
            { id: 5, title: '金' },
            // { id: 6, title: '土' },
            // { id: 7, title: '日' },
        ],
        DayOfWeek2: [
            // { id: 1, title: '月' },
            // { id: 2, title: '火' },
            // { id: 3, title: '水' },
            // { id: 4, title: '木' },
            // { id: 5, title: '金' },
            // { id: 6, title: '土' },
            // { id: 7, title: '日' },
        ],
        weeks: [
            '月',
            '火',
            '水',
            '木',
            '金',
        ],
        week: 1,
        maxPeriod: 6,
        TimeTables: null,
    }),
    props: {
        // ログインユーザーの情報を受け取る
        aikodaiId: {
            type: String,
            default: '',
        },
        password: {
            type: String,
            default: '',
        },
        timeTable: {

        },
    },
    created: function () {
        this.AikodaiId = this.aikodaiId;
        this.PassWord = this.password;
        this.TimeTables = this.timeTable;
        // console.log(jsonTimetable);

        // 今日の曜日を取得する
        this.week = new Date().getDay();
        this.changeDayOfWeek2();


    },
    methods: {
        toLoginPage: function () {
            this.$emit('to-login-page');
        },
        isDispSize: function () {
            const vuetify = usevuetify();
            return vuetify.display.xs;
        },
        sortDayOfWeek2: function (week) {
            if (week == 0 || week == 6) {
                week = 1;
            }
            for (let i = 0; i < 5; i++) {
                if (week + i > 5) {
                    week = -i + 1;
                }
                this.DayOfWeek2.push(this.DayOfWeek[week + i]);
            }
        },
        changeDayOfWeek2: function (w=0) {
            this.DayOfWeek2 = [];
            // console.log(w);
            if(w != 0){
                this.week = w;
            }
            if (this.week == 0 || this.week == 6) {
                this.week = 1;
            }
            // console.log(this.week);
            for (let i = 0; i < 5; i++) {
                if (this.week + i > 5) {
                    this.week = -i + 1;
                }
                this.DayOfWeek2.push(this.DayOfWeek[this.week + i]);
            }
        },
        
    },
    computed: {
        getCardHeight() {
            const vuetify = usevuetify();
            return vuetify.display.xs ? '100' : '100';
        },
        getCardWidth() {
            const vuetify = usevuetify();
            return vuetify.display.xs ? '250' : '130';
        },
    }
}
</script>
<style scoped>
th#youbi-1 {
    background-color: #9575CD;
}
th#youbi-2 {
    background-color: #FFB74D;
}
th#youbi-3 {
    background-color: #64B5F6;
}
th#youbi-4 {
    background-color: #81C784;
}
th#youbi-5 {
    background-color: #FFF176;
}
th#youbi-6 {
    background-color: #1976D2;
}
th#youbi-7 {
    background-color: #388E3C;
}
td#kamoku {
    /* 中央に配置する */
    text-align: center;
}
div.panda {
    display: inline-block;
}
th.space {
    width: 30px;
}
</style>
