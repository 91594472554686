<template>
    <div v-if='this.Now_yakusa != ""'>
        <div v-if="this.CarFood.length != 0">
            <!-- justify contentsを中央揃えに -->
            <v-row justify="center">
                <v-col cols="12" sm="12">
                    <v-sheet min-height="70vh" rounded="lg" id="scrolling-techniques-3">
                        <!-- ここに書く -->
                        <v-row justify="center">
                            <v-col cols="12" sm="12" class="pa-4 px-6">
                                <v-card class="mx-auto" max-width="500" variant="elevated">
                                    <v-card-title class=" justify-space-between pa-0">
                                        <v-toolbar color="purple-lighten-1" density="compact"
                                            :height="isDisplayxs() ? 'text-caption' : 'auto'" class="mb-4">
                                            <!-- <v-spacer></v-spacer> -->
                                            <div class="text-left text-white ml-2" :class="isDisplayxs() ? 'text-caption' : 'auto'">
                                                <!-- 日付を表示 -->
                                                <v-icon class="pb-2 pt-1"
                                                    :size="isDisplayxs() ? 'x-small' : 'auto'">mdi-calendar</v-icon>
                                                {{ this.Month }}月{{ this.Day }}日
                                            </div>
                                            <v-spacer></v-spacer>
                                            <div class="text-center text-white" :class="isDisplayxs() ? 'text-caption' : 'auto'">
                                                <v-icon class="pb-2  pt-1"
                                                    :size="isDisplayxs() ? 'x-small' : 'auto'">mdi-clock</v-icon>
                                                現在時刻:{{ this.clock }}
                                            </div>
                                            <v-spacer></v-spacer>
                                        </v-toolbar>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-row justify="center">
                                            <v-col xs="12">
                                                <v-row justify="center">
                                                    <v-col xs="12" :class="isDisplayxs() ? 'pa-0 pt-2' : 'auto'">
                                                        <div class="text-center"
                                                            :class="isDisplayxs() ? 'text-caption' : 'auto'">
                                                            八草発
                                                        </div>

                                                        <div class="text-center"
                                                            :class="isDisplayxs() ? 'text-body-2 pt-0.5' : 'pt-3'">
                                                            {{ this.UntillBusYkusa }}
                                                        </div>
                                                    </v-col>

                                                    <v-col xs="12" :class="isDisplayxs() ? 'pa-0 pt-2' : 'auto'">
                                                        <div class="text-center"
                                                            :class="isDisplayxs() ? 'text-caption' : 'auto'">
                                                            愛工大発
                                                        </div>

                                                        <div class="text-center"
                                                            :class="isDisplayxs() ? 'text-body-2 pt-0.5' : 'pt-3'">
                                                            {{ this.UntillBusAit }}
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>

                                            <v-col xs="12" :class="isDisplayxs() ? 'pa-0 pt-2' : 'auto'">
                                                <div class="text-center" :class="isDisplayxs() ? 'text-caption' : ''">
                                                    次の講義まで
                                                </div>

                                                <div class="text-center"
                                                    :class="isDisplayxs() ? 'text-body-2 pt-0.5' : 'pt-3'">
                                                    {{ this.UntilClass }}
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="6" class="px-6">
                                <v-card class="mx-auto" :max-width="isDisplayxs() ? '170' : '340'" variant="elevated"
                                    color="">
                                    <v-card-title class=" justify-space-between pa-0">
                                        <v-toolbar color="teal-lighten-1" density="compact"
                                            :height="isDisplayxs() ? 'text-caption' : 'auto'" class="mb-4">
                                            <div class="text-left ml-2" :class="isDisplayxs() ? 'text-caption' : 'auto'">
                                                <v-icon class="pb-2  pt-1"
                                                    :size="isDisplayxs() ? 'x-small' : 'auto'">mdi-bus</v-icon>
                                                次のバス
                                            </div>
                                        </v-toolbar>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-row justify="center">

                                            <v-col xs="12" :class="isDisplayxs() ? 'pa-0 pt-2' : 'auto'">
                                                <div class="text-center" :class="isDisplayxs() ? 'text-caption' : 'auto'">
                                                    八草発
                                                </div>

                                                <div class="text-center"
                                                    :class="isDisplayxs() ? 'text-body-2 pt-0.5' : 'pt-3'">
                                                    {{ this.Now_yakusa }}
                                                </div>
                                            </v-col>

                                            <v-col xs="12" :class="isDisplayxs() ? 'pa-0 pt-2' : 'auto'">
                                                <div class="text-center" :class="isDisplayxs() ? 'text-caption' : 'auto'">
                                                    愛工大発
                                                </div>

                                                <div class="text-center"
                                                    :class="isDisplayxs() ? 'text-body-2 pt-0.5' : 'pt-3'">
                                                    {{ this.Now_ait }}
                                                </div>
                                            </v-col>

                                        </v-row>


                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="6" class="px-6">
                                <v-card class="mx-auto" :max-width="isDisplayxs() ? '170' : '340'" variant="elevated"
                                    color="">
                                    <v-card-title class=" justify-space-between pa-0">
                                        <v-toolbar color="blue-lighten-1" density="compact"
                                            :height="isDisplayxs() ? 'text-caption' : 'auto'" class="mb-4">
                                            <div class="text-left ml-2" :class="isDisplayxs() ? 'text-caption' : 'auto'">
                                                <v-icon class="pb-2  pt-1"
                                                    :size="isDisplayxs() ? 'x-small' : 'auto'">mdi-school</v-icon>
                                                次の講義
                                            </div>
                                        </v-toolbar>
                                    </v-card-title>
                                    <v-card-text>
                                        <div v-if="this.AikodaiId != 'guest' && this.AikodaiId != ''">

                                            <v-row justify="center" v-if="Now_lecture.period != 0">

                                                <v-col xs="12" :class="isDisplayxs() ? 'pa-0 pt-2' : 'auto'">
                                                    <div class="text-center"
                                                        :class="isDisplayxs() ? 'text-caption' : 'auto'">
                                                        講義名
                                                    </div>

                                                    <div class="text-center"
                                                        :class="isDisplayxs() ? 'text-caption pt-0.5' : 'pt-3'">
                                                        {{ this.Now_lecture.name }}
                                                    </div>
                                                </v-col>

                                                <v-col xs="12" :class="isDisplayxs() ? 'pa-0 pt-2' : 'auto'">
                                                    <div class="text-center"
                                                        :class="isDisplayxs() ? 'text-caption' : 'auto'">
                                                        場所
                                                    </div>

                                                    <div class="text-center"
                                                        :class="isDisplayxs() ? 'text-body-2 pt-0.5' : 'pt-3'">
                                                        {{ this.Now_lecture.locate }}
                                                    </div>
                                                </v-col>

                                            </v-row>
                                            <v-row justify="center" v-else>
                                                <v-col xs="12" :class="isDisplayxs() ? 'pa-0 pt-2' : 'auto'">
                                                    <div class="text-center"
                                                        :class="isDisplayxs() ? 'text-caption' : 'auto'">
                                                        今日の講義は終了しました
                                                        <br>
                                                        お疲れ様でした!
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <div v-else class="text-center" :class="isDisplayxs() ? 'text-caption' : 'auto'">
                                            この機能を利用するにはログインが必要です
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" class="pa-4 px-6">
                                <v-card class="mx-auto" max-width="500" variant="elevated" color="">
                                    <v-card-title class=" justify-space-between pa-0">
                                        <v-toolbar color="orange-lighten-1" density="compact"
                                            :height="isDisplayxs() ? 'text-caption' : 'auto'" class="mb-4">
                                            <!-- <v-spacer></v-spacer> -->
                                            <div class="text-left text-white ml-2" :class="isDisplayxs() ? 'text-caption' : 'auto'">
                                                <v-icon class="pb-2  pt-1"
                                                    :size="isDisplayxs() ? 'x-small' : 'auto'">mdi-food</v-icon>
                                                今日の移動販売車
                                            </div>
                                        </v-toolbar>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-row justify="center">

                                            <v-col xs="12" :class="isDisplayxs() ? 'pa-0 pt-2' : 'auto'">
                                                <div class="text-center" :class="isDisplayxs() ? 'text-caption' : 'auto'">
                                                    一号館前
                                                </div>

                                                <div class="text-center"
                                                    :class="isDisplayxs() ? 'text-body-2 pt-0.5' : 'pt-3'">
                                                    {{ this.CarFood[0] }}
                                                </div>
                                            </v-col>

                                            <v-col xs="12" :class="isDisplayxs() ? 'pa-0 pt-2' : 'auto'">
                                                <div class="text-center" :class="isDisplayxs() ? 'text-caption' : 'auto'">
                                                    セントラルガーデン
                                                </div>

                                                <div class="text-center"
                                                    :class="isDisplayxs() ? 'text-body-2 pt-0.5' : 'pt-3'">
                                                    {{ this.CarFood[1] }}
                                                </div>
                                            </v-col>

                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
        </div>
        <div v-else>
            <KitchenCar @car-ok="CarData"></KitchenCar>
        </div>
    </div>
    <div v-else>
        <BusSchedule @bus-ok="BusData"></BusSchedule>

    </div>
</template>
<script>
// import jsonTimetable from "@/assets/json/timetable.json"

import { getCurrentInstance } from 'vue';
import BusSchedule from './BusSchedule.vue';
import KitchenCar from "./KitchenCar.vue";

const usevuetify = () => {
    const instance = getCurrentInstance()
    if (!instance) {
        throw new Error(`vuetify instance doesn't exist`)
    }
    return instance.proxy.$vuetify
}

export default {
    name: 'WelcomePage',
    data: () => ({
        Month: "",
        Day: "",
        clock: "00:00:00",
        UntillBusYkusa: "",
        UntillBusAit: "",
        UntilClass: "",
        Now_yakusa: "",
        Now_ait: "",
        TimeTables: [],
        Now_lecture: null,
        weeks: [
            '月',
            '火',
            '水',
            '木',
            '金',
        ],
        week: 0,
        AikodaiId: "",
        CarFood: [],

    }),
    components: {
        BusSchedule,
        KitchenCar,
    },
    props: {
        // バスのダイヤ
        nowYakusa: {
        },
        nowAit: {

        },
        aikodaiId: {
            type: String,
            default: '',
        },
        timeTable: {

        },
    },
    methods: {
        period2time: function (period) {
            if (period == 1) {
                return "9:00"
            } else if (period == 2) {
                return "10:40"
            } else if (period == 3) {
                return "13:00"
            } else if (period == 4) {
                return "14:40"
            } else if (period == 5) {
                return "16:20"
            } else if (period == 6) {
                return "18:00"
            } else {
                return "今日の講義は終了しました"
            }
        },
        tick: function () {// 現在時刻を取得する関数
            // console.log("tick!!");

            const date = new Date();  // Dateオブジェクトを作る
            this.Month = date.getMonth() + 1;  // 月は0から始まるので+1する
            this.Day = date.getDate();
            let h = date.getHours();  // 時
            let m = date.getMinutes();// 分
            let s = date.getSeconds();// 秒

            if (h < 10) h = "0" + h;   // 10未満であれば頭に0を付ける
            if (m < 10) m = "0" + m;
            if (s < 10) s = "0" + s;
            this.clock = h + ":" + m + ":" + s;// 文字列を結合する
            // バスの時刻から現在時刻を引く
            if (this.Now_yakusa != "" || this.Now_yakusa != '今日はもうありません') {
                const yakusa = this.Now_yakusa.split(":");
                const ait = this.Now_ait.split(":");
                const now = this.clock.split(":");
                const yakusaTime = (yakusa[0] * 60) + parseInt(yakusa[1]);
                const aitTime = (ait[0] * 60) + parseInt(ait[1]);
                const nowTime = (now[0] * 60) + parseInt(now[1]);
                const yakusaUntill = yakusaTime - nowTime;
                const aitUntill = aitTime - nowTime;
                this.UntillBusYkusa = yakusaUntill + "分後"
                this.UntillBusAit = aitUntill + "分後"
            }
            // 講義の時刻から現在時刻を引く
            if (this.Now_lecture != null) {
                if (this.Now_lecture.period == 0) {
                    this.UntilClass = "今日の講義は終了しました"
                }
                else {
                    const lecture = this.period2time(this.Now_lecture.period).split(":");
                    const now = this.clock.split(":");
                    const lectureTime = (lecture[0] * 60) + parseInt(lecture[1]);
                    const nowTime = (now[0] * 60) + parseInt(now[1]);
                    const lectureUntill = lectureTime - nowTime;
                    // 60分以上の時は時間に変換
                    if (lectureUntill >= 60) {
                        const hour = Math.floor(lectureUntill / 60);
                        const minute = lectureUntill % 60;
                        this.UntilClass = hour + "時間" + minute + "分後"
                    } else {
                        this.UntilClass = lectureUntill + "分後"
                    }
                }
            }


            // 100ミリ秒経ったら1度だけ実行する
            setTimeout(() => {
                this.tick();// tick関数を実行する
            }, 100);
        },
        BusData(now_yakusa, now_ait) {
            // console.log("BusData")
            this.Now_yakusa = now_yakusa
            this.Now_ait = now_ait
            if (this.Now_yakusa == null) {
                this.Now_yakusa = "今日はもうありません"
            }
            if (this.Now_ait == null) {
                this.Now_ait = "今日はもうありません"
            }
        },
        CarData(Food1, Food2) {
            // console.log("CarData")
            if (Food1 == null) {
                Food1 = "今日はありません"
            }
            if (Food2 == null) {
                Food2 = "今日はありません"
            }
            this.CarFood.push(Food1)
            this.CarFood.push(Food2)
        },
        lectureData: function () {
            const date = new Date();
            this.week = date.getDay();
            let timeH = date.getHours();
            let timeM = date.getMinutes();
            let time = timeH * 60 + timeM;
            if (this.week == 0 || this.week == 6) {
                this.week = 1;
                time = 0;
            }

            // デバッグ用
            // time = 0;

            // 時間をピリオドに変換
            if (time <= 540) {
                time = 1;
            } else if (time <= 640) {
                time = 2;
            } else if (time <= 780) {
                time = 3;
            } else if (time <= 880) {
                time = 4;
            } else if (time <= 980) {
                time = 5;
            } else if (time <= 1080) {
                time = 6;
            } else {
                time = 0;
                this.Now_lecture = { "period": 0 }
                return;
            }
            for (let classData of this.TimeTables) {
                if (classData.youbi == this.weeks[this.week - 1]) {
                    while (this.Now_lecture == null) {
                        for (let classes of classData.classes) {
                            if (classes.period == time) {
                                this.Now_lecture = classes;
                                return;
                            }
                        }
                        time++;
                        if (time > 6) {
                            this.Now_lecture = { "period": 0 }
                            return;
                        }
                    }

                }
            }


            // console.log(time)
        },

        isDisplayxs: function () {
            const vuetify = usevuetify();
            return vuetify.display.xs;
        },
        getTextSizeClock: function () {
            if (this.isDisplayxs()) {
                return 'text-h6'
            } else {
                return 'text-h4'
            }
        },
        getIconSizeClock: function () {
            if (this.isDisplayxs()) {
                return 'small'
            } else {
                return 'x-large'
            }
        },
        getTextSizeTitle: function () {
            if (this.isDisplayxs()) {
                return 'text-body-2'
            } else {
                return 'text-h6'
            }
        },
        getTextSizeBody: function () {
            if (this.isDisplayxs()) {
                return 'text-caption'
            } else {
                return 'text-body-1'
            }
        },
    },

    created: function () {
        new Promise((resolve) => {
            this.TimeTables = this.timeTable;
            resolve();
        }).then(() => {
            this.lectureData();
        });
        this.AikodaiId = this.aikodaiId;
        this.tick();


    },
} 
</script>
