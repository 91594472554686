<template>
    <v-app id="inspire">
        <v-app-bar class="px-3" color="white" flat density="compact">
            <v-toolbar-title>AIT Plus</v-toolbar-title>
            <!-- ↓デバッグ用 -->
            <!-- <span>{{ AikodaiId }}</span> -->
            <!-- <span v-if="form">yes</span> -->
        </v-app-bar>
        <v-main class="white">
            <v-card class="mx-auto" max-width="500">
                <v-card-title class="text-h6 font-weight-regular justify-space-between">
                    <span>Welcome to AITPlus!!</span>
                </v-card-title>
                <v-window v-model="step">
                    <v-window-item :value="1" reverse-transition="fade-transition" transition="fade-transition">
                        <v-card-text>
                            <v-form v-model="form">
                                <v-text-field v-model="AikodaiId" :rules="[required]" :readonly="loading" clearable
                                    label="愛工大ID" placeholder="例:a12345bc"></v-text-field>
                                <div>
                                    <v-alert v-model="alert" type="info" variant="tonal" closable color="blue-lighten-1">
                                        <span>パスワードはL-Camにログインする際に使用するパスワードを入力してください。</span>
                                    </v-alert>
                                    <div v-if="!alert" class="text-caption text-decoration-none text-blue ">
                                        <v-btn @click="alert = true" size="xsmal" variant="text">
                                            パスワードが分からない？
                                        </v-btn>
                                    </div>
                                </div>
                                <v-text-field v-model="PassWord" :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show1 ? 'text' : 'password'" :rules="[required]" :readonly="loading" clearable
                                    label="パスワード" placeholder="" @click:append-inner="show1 = !show1"></v-text-field>
                                <!-- <v-checkbox label="ログイン情報を保存する" value="save"></v-checkbox> -->
                                <span class="text-caption text-grey-darken-1">
                                    より良いサービスをご利用いただくために、愛工大IDでログインしてください。
                                </span>
                            </v-form>
                        </v-card-text>
                    </v-window-item>
                    <v-window-item :value="0" reverse-transition="fade-transition" transition="fade-transition">
                        <v-card-text>
                            <div class="pa-4 text-center">
                                <v-img class="mb-4" contain height="128" src="../img/logo.png"></v-img>
                                <h3 class="text-h6 font-weight-light mb-2">
                                    AITPlusへようこそ！
                                </h3>
                                <span class="text-caption text-grey">ログインは後からでも行うことができます</span>
                            </div>
                        </v-card-text>
                    </v-window-item>
                    <v-window-item :value="2" transition="fade-transition">
                        <v-card-text>
                            <div class="pa-4 text-center">
                                <v-img class="mb-4" contain height="128" src="../img/logo.png"></v-img>
                                <h3 v-if="TestSucsess && loading" class="text-h6 font-weight-light mb-2">
                                    L-Camにログイン出来るかどうか試しています
                                </h3>
                                <h3 v-if="TestSucsess && !loading" class="text-h6 font-weight-light mb-2">
                                    AITPlusへようこそ！
                                </h3>
                                <v-alert v-if="!TestSucsess" title="L-Camにログイン出来ませんでした" text="愛工大IDまたはパスワードが間違っている恐れがあります。"
                                    type="error"></v-alert>
                            </div>
                        </v-card-text>
                    </v-window-item>
                    <v-window-item :value="3" transition="fade-transition">
                        <v-card-text>
                            <div class="pa-4 text-center">
                                <v-img class="mb-4" contain height="128" src="../img/logo.png"></v-img>
                                <h3 class="text-h6 font-weight-light mb-2">
                                    AITPlusへようこそ！
                                </h3>
                                <span class="text-caption text-grey">ログインは後からでも行うことができます</span>
                            </div>
                        </v-card-text>
                    </v-window-item>

                </v-window>
                <v-card-actions>
                    <v-btn v-if="step == 1" class="text-decoration-underline" color="blue-darken-1" variant="text"
                        @click="step--">
                        スキップして利用を開始する
                    </v-btn>
                    <v-btn v-if="step == 0" class="text-decoration-underline" color="blue-darken-1" variant="text"
                        @click="step++">
                        ログイン画面に戻る
                    </v-btn>
                    <v-btn v-if="step == 2" class="text-decoration-underline" color="blue-darken-1" variant="text"
                        @click="step--">
                        ログイン画面に戻る
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn v-if="step == 1" :disabled="!form" :loading="loading" color="primary" variant="flat"
                        @click="LoginTest">
                        ログイン
                    </v-btn>
                    <v-btn v-if="step == 2 && TestSucsess" :disabled="!form" :loading="loading" color="primary"
                        variant="flat" @click="LoginOk">
                        利用を開始する
                    </v-btn>
                    <v-btn v-if="step == 0" :loading="loading" color="primary" variant="flat" @click="SkipLogin">
                        ゲストとして利用を開始する
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-main>
    </v-app>
</template>
<script>
import jsonTimetable from "@/assets/json/timetable.json"


export default {

    name: 'LoginPage',
    data: () => ({
        alert: false,
        step: 1,
        show1: false,
        form: false,
        loading: false,
        AikodaiId: 0,
        PassWord: 0,
        TestSucsess: true,
        timeTable: {},
        Debug: false,
    }),
    computed: {

    },

    props: {
        // ログインユーザーの情報を受け取る
        aikodaiId: {
            type: String,
            default: '',
        },
        password: {
            type: String,
            default: '',
        },
        debug: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        SkipLogin: function () {
            this.AikodaiId = 'guest';
            this.PassWord = 'guest';

            this.LoginOk();
        },
        LoginTest: function () {

            this.step++;
            this.loading = true;
            if (this.Debug) {
                this.TestSucsess = true;
                this.loading = false;
                this.timeTable = jsonTimetable;
                return;
            }
            const aitId = this.AikodaiId;
            const aitPass = this.PassWord;
            const timeTableUrl = `https://api.aitplus.hyouhyan.com/timetable/index.php?id=${aitId}&pass=${aitPass}`;
            fetch(timeTableUrl)
                .then(result => result.json())
                .then((output) => {
                    // console.log('Output: ', output);
                    this.loading = false;
                    this.TestSucsess = true;
                    this.timeTable = output;
                })
                .catch(err => {
                    console.error(err)
                    this.loading = false;
                });
            // setTimeout(() => {
            //     this.loading = false;
            //     if (Math.floor(Math.random() * 1) == 0) {
            //         // デバッグの時はここをfalseにすると失敗する
            //         this.TestSucsess = true;
            //     }
            // }, 2000);
        },
        LoginOk: function () {
            this.$emit('login-ok', this.AikodaiId, this.PassWord, this.timeTable);
        },
        required(v) {
            return !!v || 'フィールドは必須項目です'
        },
    },
    created: function () {
        this.AikodaiId = this.aikodaiId;
        this.PassWord = this.password;
        this.Debug = this.debug;
        if (this.Debug) {
            this.AikodaiId = 'a12345bc'
            this.PassWord = 'test'
        }

    },
};
</script>