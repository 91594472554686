<template>
    <div>
        <v-tabs centered color="grey-darken-2" v-model="tab">
            <v-tab v-for="link in links" :key="link.id" :value="link.id" color="teal-lighten-2">
                {{ link.title }}
            </v-tab>
            <v-spacer></v-spacer>
        </v-tabs>
        <!-- justify contentsを中央揃えに -->
        <v-row justify="center">
            <v-col cols="12" sm="12">
                <v-sheet class="pt-10 pa-5" min-height="70vh" rounded="lg" id="scrolling-techniques-3">
                    <!-- ここに書く -->
                    <v-window v-model="tab">
                        <v-window-item v-for="link in  links " :key="link.id" :value="link.id">
                            <!-- お知らせのウィンドウ -->
                            <div v-if="link.id == 1">
                                <v-row :align="center" justify="center" v-for="x in OsiraseData" :key="x.title">
                                    <v-spacer></v-spacer>
                                    <v-col cols="12" sm="12">
                                        <v-card class="mx-auto" max-width="500" variant="outlined" color="">
                                            <v-card-title class=" justify-space-between">
                                                <v-icon class="pr-4">{{ x.icon }}</v-icon>

                                                <span class=" font-weight-regular" :class="getTextSizeTitle()" style="color:;">{{ x.title
                                                }}</span>
                                            </v-card-title>
                                            <v-card-text>
                                                <div class="pa-4 text-center">
                                                    <h3 class=" font-weight-light mb-2" :class="getTextSizeBody()">
                                                        {{ x.body }}
                                                    </h3>

                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                </v-row>
                            </div>
                            <!-- アップデート情報のウィンドウ -->
                            <div v-if="link.id == 2">
                            </div>
                        </v-window-item>
                    </v-window>
                </v-sheet>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import informationJson from "@/assets/json/Osirase.json"
import { getCurrentInstance } from 'vue';

const usevuetify = () => {
    const instance = getCurrentInstance()
    if (!instance) {
        throw new Error(`vuetify instance doesn't exist`)
    }
    return instance.proxy.$vuetify
}

export default {
    name: 'InforMation',
    data: () => ({
        tab: null,
        links: [
            { id: 1, title: 'お知らせ' },
            { id: 2, title: 'アップデート情報' },
        ],
        OsiraseData: informationJson,
        UpdateData: [

        ],
    }),
    methods: {
        isDisplayxs: function () {
            const vuetify = usevuetify();
            return vuetify.display.xs;
        },
        getTextSizeTitle: function () {
            if (this.isDisplayxs()) {
                return 'text-body-2'
            } else {
                return 'text-h6'
            }
        },
        getTextSizeBody: function () {
            if (this.isDisplayxs()) {
                return 'text-caption'
            } else {
                return 'text-body-1'
            }
        },
    },
    created: function () {
        // console.log(this.OsiraseData)
    },
} 
</script>
